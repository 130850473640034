@import "./animations.scss";
@import "./def";
@import "./buttons.scss";

* {
  margin: 0;
  padding: 0;
}

html,
body,
input {
  font-family: 'Nunito', sans-serif;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
}

.color-primary {
  color: $primary;
}

#app {
  width: 100%;
  margin: 0 auto 96px auto;
  max-width: 1024px;
  transition: opacity 500ms ease;
}