$footer-reveal-at: 80px;

#app {
  padding-bottom: 64px; // Footer space.
}

.global-footer {
  &,
  p {
    color: #757575;
  }
  text-align: center;
  border-top: 2px solid #eee;
  position: sticky;
  bottom: -100px;
  background: #fff;

  .content {
    padding: 24px;
    max-width: 960px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;

    > * {
      padding: 6px 0;
    }
  }

  .contact {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

    li {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .icon {
        margin-right: 8px;
        width: 18px;
        height: 24px;
      }

      .value {
        font-size: 17px;
        color: #616161;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.global-footer-sticky-container {
  position: sticky;
  position: -webkit-sticky;
  top: -$footer-reveal-at;
  width: 100%;
  z-index: 990;
  pointer-events: none;
}

.global-footer-abs-container {
  position: absolute;
  top: $footer-reveal-at;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;

  .global-footer {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    pointer-events: auto;
  }
}

@media (min-width: 1020px) {
  .global-footer {
    .contact {
      padding-left: 36px;
    }
  }
}