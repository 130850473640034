@import "./def";

.button {
  white-space: nowrap;
  margin: 0;
  font-weight: 400;
  padding: 8px 16px;
  font-size: 15px;
  border-radius: 8px;
  height: 42px;
  box-sizing: border-box;

  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  transition: background-color 250ms cubic-bezier(0.215, 0.61, 0.355, 1);

  user-select: none;

  &.icon-only {
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    padding: 0 !important;
    border-radius: 50%;

    .icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin: 0 !important;
    }
  }

  outline: none !important;

  &:focus {
    &::after {
      position: absolute;
      content: " ";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: 2px solid $primary-light;
      border-radius: 3px;
      box-sizing: border-box;
    }
  }
  
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not([disabled]):hover {
    cursor: pointer;
  }

  &:not([disabled]):active {
    transition: background-color 25ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .body {
    padding: 4px 4px 4px 0;
  }

  &.small-caps {
    /* font-variant: small-caps; */
  }

  .icon,
  .task-indicator {
    font-size: 18px;
    
    &:first-child {
      margin-right: 8px;
    }
    &:not(:first-child) {
      margin-left: 8px;
    }
  }

  .icon {
    font-size: 13px;
  }

  /* weighs */
  &.primary {
    color: #fafafa;
    background-color: $primary;
    border: none;

    .icon,
    .task-indicator {
      color: #fafafa;
    }

    &:not([disabled]):active {
      //background-color: $primary-dark1;
    }
  }

  &.normal,
  &.normal-border,
  &.normal-dark,
  &.dimmed,
  &.danger {
    border: none;
    color: $primary;
    background-color: transparent;

    &.background-translucent-white {
      background-color: rgba(250, 250, 250, 0.75);
    }

    &.background-white {
      background-color: #fff;
    }

    &.button:not([disabled]):active {
      background-color: rgba(20, 20, 20, 0.05);

      &.background-translucent-white {
        background-color: rgba(250, 250, 250, 0.95);
      }

      &.background-white {
        background-color: rgba(255, 255, 255, 0.95);
      }
    }

    &:not([disabled]):hover {
      background-color: rgba(20, 20, 20, 0.035);

      &.background-translucent-white {
        background-color: rgba(250, 250, 250, 1);
      }

      &.background-white {
        background-color: rgba(225, 225, 225, 1);
      }
    }
  }

  &.icon-enlarged {
    .icon {
      font-size: 24px;

      &:first-child {
        margin-right: 4px;
      }
      &:not(:first-child) {
        margin-left: 4px;
      }
    }
  }

  &.normal-dark {
    color: #fafafa;
  }

  &.primary,
  &.normal-border {
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.15);
  }

  &.normal,
  &.danger,
  &.dimmed {
    padding: 4px 10px;
  }

  &.dimmed {
    background: transparent;

    &,
    .icon,
    .task-indicator {
      color: #757575;
    }
    border: none;
  }

  .badge {
    $size: 6px;

    width: $size;
    height: $size;

    border-radius: 50%;
    background: $primary;
    margin-left: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-10px);
    right: 16px;

    &.offset {
      right: 0;
    }
  }
}